import api from "@/api/api";

export async function buscarAlunos(idAuto, filtros = {}) {
  const response = await api.get(
    `/alunos/buscaAlunosPorAutoEscolaID/${idAuto}`,
    {
      params: filtros,
    }
  );
  return response.data.data;
}

export async function buscaTodosAlunosAutoEscola(idAuto, searchText) {
  const response = await api.get(
    `/alunos/buscaTodosAlunosAutoEscola/${idAuto}`,
    {
      params: {
        search: searchText,
      },
    }
  );
  return response.data.data;
}

export async function fetchQtdAlunos(idAutoEscola, period) {
  const response = await api.get(
    `/alunos/buscaQtdAlunosAutoEscola/${idAutoEscola}?period=${period}`
  );
  return response.data.data;
}

export async function buscarAlunoPorId(id) {
  const response = await api.get(`/alunos/${id}`);
  return response.data;
}

export async function cadastrarAluno(dados) {
  const response = await api.post("/alunos/novoAluno", dados);
  return response.data;
}

export async function atualizarAluno(id, dados) {
  const response = await api.put(`/alunos/${id}`, dados);
  return response.data;
}
