import api from "@/api/api";

export async function cadastrarModalidade(nome) {
  const response = await api.post("/modalidade/novaModalidade", { nome });
  return response.data;
}

export async function buscarModalidades() {
  const response = await api.get("/modalidade/buscaModalidades");
  return response.data.data;
}

export async function buscarModalidadePorId(id) {
  const response = await api.get(`/modalidade/buscaModalidadePorId/${id}`);
  return response.data.data;
}

export async function atualizarModalidade(id, dados) {
  const response = await api.put(`/modalidade/atualizaModalidade/${id}`, dados);
  return response;
}
