import api from "@/api/api";
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: "top-right" });

export async function cadastrarCargo(nome) {
  try {
    const response = await api.post("/cargo/novoCargo", { nome });
    toaster.success("✅ Cargo cadastrado com sucesso!");
    return response.data;
  } catch (error) {
    toaster.error("⚠️ Erro ao cadastrar cargo. Tente novamente mais tarde.");
    throw error;
  }
}

export async function atualizarCargo(id, dados) {
  try {
    const response = await api.put(`/cargo/atualizaCargo/${id}`, dados);
    toaster.success("✅ Cargo atualizado com sucesso!");
    return response;
  } catch (error) {
    toaster.error("⚠️ Erro ao atualizar cargo. Tente novamente mais tarde.");
    throw error;
  }
}

export async function buscarCargoPorId(id) {
  try {
    const response = await api.get(`/cargo/buscaCargoPorId/${id}`);
    return response.data.data;
  } catch (error) {
    toaster.error("⚠️ Não foi possível carregar os dados do cargo.");
    throw error;
  }
}

export async function buscarCargos() {
  try {
    const response = await api.get("/cargo/buscaCargos", {
      withCredentials: true,
    });
    return response.data.data;
  } catch (error) {
    toaster.error("⚠️ Não foi possível carregar os dados do cargo.");
    throw error;
  }
}
