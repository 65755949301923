<template>
  <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" :class="''
      "
    v-bind="$attrs" id="navbarBlur" data-scroll="true">
    <div class="px-3 py-1 container-fluid">
      <div class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4" :class="'me-sm-4'" id="navbar">
        <div class="pe-md-3 d-flex align-items-center" :class="'ms-md-auto'">
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="px-3 nav-item d-flex align-items-center">
            <a class="p-0 nav-link text-white" @click="logout">
              <i class="cursor-pointer fa fa-sign-out fixed-plugin-button-nav"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="">
                <p class="text-uppercase text-sm mt-4">Informações do Responsável</p>
                <div class="row">
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Nome</label>
                    <input v-model="nome" class="form-control mb-1" type="text" placeholder="Ex: João Silva" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">E-mail</label>
                    <input v-model="email" class="form-control mb-1" type="email" placeholder="Ex: e-mail@gmail.com" />
                  </div>

                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Telefone</label>
                    <input v-model="telefone" class="form-control mb-1" placeholder="(91) 99999-9999"
                      mask="(##) #####-####" />
                  </div>
                </div>
                <hr class="horizontal dark" />
                <p class="text-uppercase text-sm">Informações da Auto Escola</p>
                <div class="row">
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Razão Social</label>
                    <input v-model="razao_social" class="form-control mb-1" type="text" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Nome Fantasia</label><span
                      v-if="!nome_fantasia" class="error ml-2">*Por favor, preencha o nome fantasia da Auto
                      Escola.</span>
                    <input v-model="nome_fantasia" class="form-control mb-1" type="text" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">CNPJ</label><span v-if="!cnpj"
                      class="error ml-2">*Por favor, preencha o CNPJ da auto escola.</span>
                    <input v-model="cnpj" class="form-control mb-1" required mask="##.###.###/####-##" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Código Celcoin</label>
                    <input v-model="cod_efi" class="form-control mb-1" type="text" />
                  </div>
                  <div class="col-md-6">
                    <label for="tipo_empresa" class="form-control-label">Tipo da Empresa</label>
                    <select v-model="tipo_empresa" id="tipo_empresa" class="form-control mb-1" required>
                      <option value="" disabled selected>Selecione o tipo da empresa</option>

                      <option value="ltda">LTDA</option>
                      <option value="eireli">Eireli</option>
                      <option value="association">Associações/Condomínios</option>
                      <option value="individualEntrepreneur">Empresário individual</option>
                      <option value="mei">MEI</option>
                      <option value="sa">S/A</option>
                      <option value="slu">SLU</option>
                    </select>
                  </div>
                </div>
                <hr class="horizontal dark" />
                <p class="text-uppercase text-sm">Endereço</p>
                <div class="row">
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">CEP</label>
                    <input v-model="cep" class="form-control mb-1" id="cep" placeholder="XX.XXX-XXX" />
                  </div>
                  <div class="col-md-8"></div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Logradouro</label>
                    <input v-model="logradouro" class="form-control mb-1" placeholder="Ex: Rua das Rosas" type="text"
                      id="logradouro" />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Complemento</label>
                    <input v-model="complemento" class="form-control mb-1" placeholder="Ex: 10" type="text"
                      id="complemento" />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Número</label>
                    <input v-model="numero" class="form-control mb-1" placeholder="Ex: 10" type="text" id="numero"
                      required />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Bairro</label>
                    <input v-model="bairro" class="form-control mb-1" placeholder="Ex: Canudos" type="text"
                      id="bairro" />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Cidade</label>
                    <input v-model="cidade" class="form-control mb-1" placeholder="Ex: Belém" type="text" id="cidade"
                      readonly />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Estado</label>
                    <input v-model="uf" class="form-control mb-1" placeholder="Ex: Pará" type="text" id="estado"
                      readonly />
                  </div>
                </div>

                <hr class="horizontal dark" />

                <argon-button color="success" size="sm" class="ms-auto mt-2" @click="cadAuto"
                  :disabled="submitting">Salvar</argon-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { buscarAutoEscolaPorId, cadastrarAutoEscola, atualizarAutoEscola } from "@/api/auto.api";
import { createToaster } from "@meforma/vue-toaster";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "CadAutoComponent",
  components: { ArgonButton },

  data() {
    return {
      produtos: [],
      produtosSelecionados: [],
      nome: "",
      email: "",
      cpf: "",
      telefone: "",
      razao_social: "",
      nome_fantasia: "",
      cnpj: "",
      tipo_empresa: "",
      tipo_servico: "",
      cep: "",
      logradouro: "",
      numero: "",
      bairro: "",
      complemento: "",
      cidade: "",
      estado: "",
      senha: "",
      submitting: false,
      toaster: createToaster({}),
    };
  },

  methods: {
    async loadAuto() {
      const id = this.$route.params.id;
      if (id) {
        try {
          const token = this.$store.state.token;
          const autoData = await buscarAutoEscolaPorId(id, token);

          this.nome = autoData.nome;
          this.email = autoData.email;
          this.cpf = autoData.cpf;
          this.telefone = autoData.telefone;
          this.senha = autoData.password;
          this.razao_social = autoData.auto_escola.razao_social;
          this.nome_fantasia = autoData.auto_escola.nome_fantasia;
          this.cnpj = autoData.auto_escola.cnpj;
          this.tipo_empresa = autoData.auto_escola.tipo_empresa;
          this.tipo_servico = autoData.auto_escola.tipo_servico || "";
          this.cep = autoData.endereco.cep;
          this.logradouro = autoData.endereco.logradouro;
          this.numero = autoData.endereco.numero;
          this.bairro = autoData.endereco.bairro;
          this.complemento = autoData.endereco.complemento;
          this.cidade = autoData.endereco.cidade;
          this.uf = autoData.endereco.estado;
        } catch (error) {
          console.error("❌ Erro ao carregar Auto Escola:", error);
        }
      }
    },

    async cadAuto() {
      const id = this.$route.params.id;
      const token = this.$store.state.token;

      if (this.submitting) return;
      this.submitting = true;

      try {
        const dadosCadastro = {
          userData: {
            nome: this.nome,
            email: this.email,
            telefone: this.telefone,
            senha: this.senha,
            status: "ATIVO"
          },
          autoEscolaData: {
            tipo_empresa: this.tipo_empresa,
            tipo_servico: this.tipo_servico,
            razao_social: this.razao_social,
            nome_fantasia: this.nome_fantasia,
            cnpj: this.cnpj,
            status: "ATIVO",
            obs: "Observação"
          },
          enderecoData: {
            cep: this.cep,
            logradouro: this.logradouro,
            numero: this.numero,
            bairro: this.bairro,
            cidade: this.cidade,
            estado: this.uf
          }
        };

        console.log("📨 Enviando dados:", dadosCadastro);

        if (id) {
          await atualizarAutoEscola(id, dadosCadastro, token);
          this.toaster.success("✅ Auto Escola atualizada com sucesso!", { timeout: 2000 });
        } else {
          await cadastrarAutoEscola(dadosCadastro, token);
          this.toaster.success("✅ Auto Escola cadastrada com sucesso!", { timeout: 2000 });
        }

        this.$router.push("/lista-auto");
      } catch (error) {
        console.error("❌ Erro no cadastro/atualização:", error);
        this.toaster.error("⚠️ Ocorreu um erro. Tente novamente.", { timeout: 4000 });
      } finally {
        this.submitting = false;
      }
    },

    async buscarEndereco() {
      const cepLimpo = this.cep.replace(/\D/g, "");

      if (cepLimpo.length === 8) {
        try {
          const response = await fetch(`https://viacep.com.br/ws/${cepLimpo}/json/`);
          const data = await response.json();

          if (data.erro) {
            this.toaster.error("CEP não encontrado. Verifique e tente novamente.", { timeout: 2000 });
          } else {
            this.logradouro = data.logradouro;
            this.bairro = data.bairro;
            this.cidade = data.localidade;
            this.uf = data.uf;
          }
        } catch (error) {
          console.error("❌ Erro ao buscar o CEP:", error);
          this.toaster.error("Erro ao buscar o CEP. Tente novamente.", { timeout: 2000 });
        }
      }
    },
  },

  mounted() {
    this.$store.state.isAbsolute = true;
    this.loadAuto();
  }
};
</script>
