import api from "@/api/api";

export async function buscarColaboradores(idAutoEscola) {
  const response = await api.get(
    `/colaborador/buscaTodosColaboradoresAutoEscola/${idAutoEscola}`
  );
  return response.data.data;
}

export async function fetchQtdColaborador(idAutoEscola, period) {
  const response = await api.get(
    `/colaborador/buscaQtdColaboradoresAutoEscola/${idAutoEscola}?period=${period}`
  );
  return response.data.data;
}

export async function cadastrarColaborador(dados) {
  const response = await api.post("/colaborador/novoColaborador", dados);
  return response;
}
