<template>
  <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" :class="this.$store.state.isRTL ? 'top-0 position-sticky z-index-sticky' : ''
                                                                " v-bind="$attrs" id="navbarBlur" data-scroll="true">
    <div class="px-3 py-1 container-fluid">
      <div class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="this.$store.state.isRTL ? 'px-0' : 'me-sm-4'" id="navbar">
        <div class="pe-md-3 d-flex align-items-center" :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'">
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="px-3 nav-item d-flex align-items-center">
            <a class="p-0 nav-link text-white" @click="logout">
              <i class="cursor-pointer fa fa-sign-out fixed-plugin-button-nav"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="">
                <p class="text-uppercase text-sm mt-4">Informações do Responsável</p>
                <div class="row">
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Nome</label>
                    <input v-model="nome" class="form-control mb-1" type="text" placeholder="Ex: João Silva" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">E-mail</label>
                    <input v-model="email" class="form-control mb-1" type="email" placeholder="Ex: e-mail@gmail.com" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">CPF</label>
                    <span v-if="!cpf" class="error ml-2">*Por favor, preencha o CPF do responsável.</span>
                    <MaskInput v-model="cpf" class="form-control mb-1" placeholder="XXX.XXX.XXX-XX"
                      mask="###.###.###-##" />
                  </div>

                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Telefone</label>
                    <MaskInput v-model="telefone" class="form-control mb-1" placeholder="(91) 99999-9999"
                      mask="(##) #####-####" />
                  </div>
                </div>
                <hr class="horizontal dark" />
                <p class="text-uppercase text-sm">Informações da Auto Escola</p>
                <div class="row">
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Razão Social</label>
                    <input v-model="razao_social" class="form-control mb-1" type="text" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Nome Fantasia</label><span
                      v-if="!nome_fantasia" class="error ml-2">*Por favor, preencha o nome fantasia da Auto
                      Escola.</span>
                    <input v-model="nome_fantasia" class="form-control mb-1" type="text" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">CNPJ</label><span v-if="!cnpj"
                      class="error ml-2">*Por favor, preencha o CNPJ da auto escola.</span>
                    <MaskInput v-model="cnpj" class="form-control mb-1" required mask="##.###.###/####-##" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Código Celcoin</label>
                    <input v-model="cod_efi" class="form-control mb-1" type="text" />
                  </div>
                  <div class="col-md-6">
                    <label for="tipo_empresa" class="form-control-label">Tipo da Empresa</label>
                    <select v-model="tipo_empresa" id="tipo_empresa" class="form-control mb-1" required>
                      <option value="" disabled>Selecione o tipo da empresa</option>
                      <option value="ltda">LTDA</option>
                      <option value="eireli">Eireli</option>
                      <option value="association">Associações/Condomínios</option>
                      <option value="individualEntrepreneur">Empresário individual</option>
                      <option value="mei">MEI</option>
                      <option value="sa">S/A</option>
                      <option value="slu">SLU</option>
                    </select>

                  </div>
                  <div class="col-md-6">
                    <label for="tipo_servico" class="form-control-label">CNAE</label>
                    <select v-model="tipo_servico" id="tipo_servico" class="form-control mb-1" required>
                      <option value="" disabled selected>Selecione o tipo de serviço</option>
                      <option value="8599601">Auto-Escola</option>
                      <option value="8599601">Capacitação de Condutores de Veículos
                      </option>
                      <option value="8599601">Curso de Condutores de Veículos</option>
                      <option value="8599601">Curso de Direção de Automóveis</option>
                    </select>
                    <div class="invalid-feedback">Por favor, selecione o tipo de serviço.</div>
                  </div>
                </div>
                <hr class="horizontal dark" />
                <p class="text-uppercase text-sm">
                  Produtos<span class="error ml-2">*Escolha os produtos e/ou serviços que são oferecidos pela
                    Auto Escola.</span>
                </p>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-check" v-for="(produto, index) in produtosSelecionados" :key="index">
                      <input class="form-check-input" type="checkbox" :id="'produto_' + index"
                        v-model="produto.selecionado" />
                      <label class="form-check-label" :for="'produto_' + index">
                        {{ produto.curso }}
                      </label>
                    </div>
                  </div>
                </div>
                <hr class="horizontal dark" />
                <p class="text-uppercase text-sm">Endereço</p>
                <div class="row">
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">CEP</label>
                    <MaskInput v-model="cep" class="form-control mb-1" id="cep" placeholder="XX.XXX-XXX"
                      @keyup="buscarEndereco" mask="##.###-###" />
                  </div>
                  <div class="col-md-8"></div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Logradouro</label>
                    <input v-model="logradouro" class="form-control mb-1" placeholder="Ex: Rua das Rosas" type="text"
                      id="logradouro" />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Complemento</label>
                    <input v-model="complemento" class="form-control mb-1" placeholder="Ex: 10" type="text"
                      id="complemento" />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Número</label>
                    <input v-model="numero" class="form-control mb-1" placeholder="Ex: 10" type="text" id="numero"
                      required />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Bairro</label>
                    <input v-model="bairro" class="form-control mb-1" placeholder="Ex: Canudos" type="text"
                      id="bairro" />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Cidade</label>
                    <input v-model="cidade" class="form-control mb-1" placeholder="Ex: Belém" type="text" id="cidade"
                      readonly />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Estado</label>
                    <input v-model="uf" class="form-control mb-1" placeholder="Ex: Pará" type="text" id="estado"
                      readonly />
                  </div>
                </div>
                <hr class="horizontal dark" />
                <p class="text-uppercase text-sm">Senha da Auto Escola</p>
                <span v-if="!senha" class="error ml-2">*Por favor, preencha a senha de acesso da Auto Escola</span>

                <div class="row">
                  <div class="col-md-12">
                    <input v-model="senha" class="form-control mb-1" type="text" id="senha" />
                  </div>
                </div>
                <hr class="horizontal dark" />

                <argon-button color="success" size="sm" class="ms-auto mt-2" @click="cadAuto"
                  :disabled="submitting">Salvar</argon-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { cadastrarAutoEscola } from "@/api/auto.api";
import { buscarProdutos } from "@/api/pacote.api";
import { createToaster } from "@meforma/vue-toaster";
import ArgonButton from "@/components/ArgonButton.vue";
import { MaskInput } from "vue-3-mask";

export default {
  name: "CadAutoComponent",
  components: { ArgonButton, MaskInput },

  data() {
    return {
      produtos: [],
      produtosSelecionados: [],
      nome: "",
      email: "",
      cpf: "",
      telefone: "",
      razao_social: "",
      nome_fantasia: "",
      cnpj: "",
      tipo_empresa: null,
      cep: "",
      logradouro: "",
      numero: "",
      bairro: "",
      cidade: "",
      uf: "",
      senha: "",
      submitting: false,
      toaster: createToaster({}),
    };
  },

  watch: {
    tipo_empresa(novoValor) {
      console.log("🚀 Tipo da empresa atualizado:", novoValor);
    }
  },


  methods: {
    async fetchProdutos() {
      try {
        const response = await buscarProdutos();
        this.produtos = response;
        this.produtosSelecionados = response.map(produto => ({
          ...produto,
          selecionado: false,
        }));
      } catch (error) {
        console.error("❌ Erro ao buscar produtos:", error);
        this.toaster.error("⚠️ Erro ao carregar os produtos.");
      }
    },


    async cadAuto() {
      if (this.submitting) return;
      this.submitting = true;

      try {
        const dadosCadastro = {
          nome: this.nome,
          email: this.email,
          cpf: this.cpf.replace(/\D/g, ""),
          telefone: this.telefone,
          senha: this.senha,
          status: "ATIVO",
          cep: this.cep.replace(/\D/g, ""),
          tipo_empresa: this.tipo_empresa,
          razao_social: this.razao_social,
          nome_fantasia: this.nome_fantasia,
          cnpj: this.cnpj.replace(/\D/g, ""),
          logradouro: this.logradouro,
          numero: this.numero,
          bairro: this.bairro,
          cidade: this.cidade,
          estado: this.uf,
          produtos: this.produtosSelecionados
            .filter(produto => produto.selecionado)
            .map(produto => produto.id),
          roles: ["auto_escola"],
        };

        console.log("📨 Enviando dados:", dadosCadastro);
        await cadastrarAutoEscola(dadosCadastro);

        this.toaster.success("✅ Auto Escola cadastrada com sucesso!", { timeout: 2000 });
        this.$router.push("/lista-auto");
      } catch (error) {
        this.tratarErroCadastro(error);
      } finally {
        this.submitting = false;
      }
    },

    async buscarEndereco() {
      const cepLimpo = this.cep.replace(/\D/g, "");

      if (cepLimpo.length === 8) {
        try {
          const response = await fetch(`https://viacep.com.br/ws/${cepLimpo}/json/`);
          const data = await response.json();

          if (data.erro) {
            this.toaster.error("CEP não encontrado. Verifique e tente novamente.", { timeout: 2000 });
          } else {
            this.logradouro = data.logradouro;
            this.bairro = data.bairro;
            this.cidade = data.localidade;
            this.uf = data.uf;
          }
        } catch (error) {
          console.error("❌ Erro ao buscar o CEP:", error);
          this.toaster.error("Erro ao buscar o CEP. Tente novamente.", { timeout: 2000 });
        }
      }
    },

    tratarErroCadastro(error) {
      console.error("❌ Erro no cadastro:", error);

      if (error.response?.data?.message) {
        this.toaster.error(error.response.data.message, { timeout: 4000 });
      } else {
        this.toaster.error("⚠️ Ocorreu um erro. Tente novamente mais tarde.", { timeout: 4000 });
      }
    },
  },

  mounted() {
    this.$store.state.isAbsolute = true;
    this.fetchProdutos();
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
  },

  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },
};

</script>
