<template>
  <BreadcrumbsAuto :breadcrumbs="breadcrumbs" :router="$router" />

  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12">
            <card :title="stats.money.title" :value="stats.money.value" :percentage="stats.money.percentage"
              :iconClass="stats.money.iconClass" :iconBackground="stats.money.iconBackground"
              :detail="stats.money.detail" directionReverse></card>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7 mb-lg">
            <div class="card z-index-2">
              <gradient-line-chart />
            </div>
          </div>
          <div class="col-lg-5">
            <carousel />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Card from "@/widgets/Cards/Card.vue";
import GradientLineChart from "@/widgets/Charts/GradientLineChart.vue";
import Carousel from "./components/Carousel.vue";
import BreadcrumbsAuto from "@/components/Breadcrumbs.vue";

export default {
  props: {},
  name: "dashboard-auto",
  data() {
    return {
      alunos: [],
      colaborador: [],
      vendas: [],
      selectedPeriod: "all",
      breadcrumbs: [{ label: "Home", to: "/dashboard-auto" }],
      stats: {
        money: {
          title: "Alunos",
          value: "",
          iconClass: "ni ni-circle-08",
          iconBackground: "bg-gradient-primary",
        },
      },
    };
  },
  methods: {
    async fetchData() {
      await this.fetchQtdAlunos(this.selectedPeriod);
    },

    async fetchQtdAlunos(period) {
      const token = this.$store.state.token;
      const id = this.$store.state.id_auto;

      console.log(token);
      try {
        const response = await axios.get(
          this.$baseURL +
          `/alunos/buscaQtdAlunosAutoEscola/${id}?period=${period}`,
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.alunos = response.data.data;
        console.log("--------", this.alunos);
        this.stats.money.value = this.alunos;
      } catch (error) {
        console.error("Erro ao buscar alunos:", error);
      }
    },
    watch: {
      selectedPeriod(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.fetchData();
        }
      },
    },
  },
  mounted() {
    this.fetchData();
  },
  components: {
    BreadcrumbsAuto,
    Card,
    GradientLineChart,
    Carousel,
  },
};
</script>
