<template>
  <BreadcrumbsCadModalidade :breadcrumbs="breadcrumbs" :router="$router" />

  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="cadModalidade">
                <div class="row">
                  <div class="col-md-12">
                    <label for="example-text-input" class="form-control-label">Nome</label>
                    <input required v-model="nome" class="form-control mb-1" type="text" placeholder="Ex: 1 CNH" />
                  </div>
                </div>

                <argon-button color="success" size="sm" class="ms-auto mt-2" @click="cadModalidade"
                  :disabled="submitting">Salvar</argon-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { cadastrarModalidade } from "@/api/modalidade.api";
import { createToaster } from "@meforma/vue-toaster";
import ArgonButton from "@/components/ArgonButton.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import BreadcrumbsCadModalidade from "@/components/Breadcrumbs.vue";


export default {
  name: "CadModalidadeComponent",
  components: { ArgonButton, BreadcrumbsCadModalidade },

  data() {
    return {
      breadcrumbs: [
        { label: "Home", to: "/dashboard-admin" },
        { label: "Lista Modalidades", to: "/lista-modalidade" },
      ],
      nome: "",
      submitting: false,
    };
  },

  methods: {
    async cadModalidade() {
      if (!this.nome || this.submitting) return;
      this.submitting = true;
      const toaster = createToaster({});

      try {
        await cadastrarModalidade(this.nome);

        toaster.success("✅ Modalidade cadastrada com sucesso!");
        this.$router.push("/lista-modalidade");
      } catch (error) {
        toaster.error("⚠️ Erro ao cadastrar modalidade!");
      } finally {
        this.submitting = false;
      }
    },
  },

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
  },
};
</script>
