<template>
  <BreadcrumbsExibePacote :breadcrumbs="breadcrumbs" :router="$router" />

  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="editarPacote">
                <div class="row">
                  <div class="col-md-6">
                    <label class="form-control-label">Curso</label>
                    <input v-model="curso" class="form-control mb-1" type="text" required />
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">UPF</label>
                    <input v-model="upf" type="number" class="form-control mb-1" required />
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">Valor Unitário</label>
                    <money3 v-model="val_unitario" class="form-control mb-1" v-bind="config" required />
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">Carga Horária</label>
                    <input v-model="carga_horaria" class="form-control mb-1" type="number" required />
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">Valor Total</label>
                    <money3 v-model="val_total" class="form-control mb-1" v-bind="config"></money3>
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">É possível alterar a quantidade?</label>
                    <div class="combos-container">
                      <label><input type="radio" v-model="selectedOptionRadio" value="S" required /> Sim</label>
                      <label><input type="radio" v-model="selectedOptionRadio" value="N" /> Não</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">Modalidade</label>
                    <select v-model="selectedModalidade" class="form-control" required>
                      <option disabled value="">Selecione uma modalidade</option>
                      <option v-for="modalidade in modalidades" :key="modalidade.id" :value="modalidade.id">
                        {{ modalidade.nome }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">Categoria</label>
                    <select v-model="selectedCategoria" class="form-control" required>
                      <option disabled value="">Selecione uma categoria</option>
                      <option v-for="categoria in categorias" :key="categoria.id" :value="categoria.id">
                        {{ categoria.nome }}
                      </option>
                    </select>
                  </div>
                </div>
                <argon-button color="success" size="sm" class="ms-auto mt-2" @click="editarPacote"
                  :disabled="submitting">
                  Salvar
                </argon-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { buscarProdutoPorId, atualizarProduto } from "@/api/pacote.api"; // ✅ API externa
import { buscarCategorias } from "@/api/categoria.api";
import { buscarModalidades } from "@/api/modalidade.api";
import { createToaster } from "@meforma/vue-toaster";
import ArgonButton from "@/components/ArgonButton.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { Money3Component } from "v-money3";
import BreadcrumbsExibePacote from "@/components/Breadcrumbs.vue";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "EditaPacote",
  components: { ArgonButton, money3: Money3Component, BreadcrumbsExibePacote },

  data() {
    return {
      breadcrumbs: [{ label: "Home", to: "/dashboard-admin" }],
      config: {
        masked: false,
        prefix: "R$ ",
        thousands: ".",
        decimal: ",",
        precision: 2,
      },
      selectedCategoria: null,
      selectedModalidade: null,
      curso: "",
      upf: "",
      val_unitario: "",
      carga_horaria: "",
      val_total: "",
      selectedOptionRadio: null,
      modalidades: [],
      categorias: [],
      submitting: false,
      toaster: createToaster({}),
    };
  },

  methods: {
    async carregarDados() {
      try {
        this.modalidades = await buscarModalidades();
        this.categorias = await buscarCategorias();
      } catch (error) {
        this.toaster.error("⚠️ Erro ao carregar modalidades e categorias.");
      }
    },

    async carregarPacote() {
      try {
        const id = this.$route.params.id;
        console.log("🔍 Buscando pacote com ID:", id);

        const response = await buscarProdutoPorId(id);
        console.log("✅ Resposta da API:", response);

        const pacote = Array.isArray(response) ? response[0] : response;

        if (!pacote) {
          this.toaster.error("⚠️ Pacote não encontrado.");
          return;
        }

        this.curso = pacote.curso || "";
        this.upf = pacote.upf || "";
        this.val_unitario = pacote.val_unitario || "";
        this.carga_horaria = pacote.carga_horaria || "";
        this.val_total = pacote.val_total || "";
        this.selectedCategoria = pacote.categoriaId || null;
        this.selectedModalidade = pacote.modalidadeId || null;
        this.selectedOptionRadio = pacote.qtd_editavel ? "S" : "N";
      } catch (error) {
        console.error("❌ Erro ao carregar pacote:", error);
        this.toaster.error("⚠️ Erro ao carregar pacote.");
      }
    },

    async editarPacote() {
      if (this.submitting) return;
      this.submitting = true;

      try {
        const dados = {
          curso: this.curso,
          upf: this.upf,
          val_unitario: this.val_unitario,
          carga_horaria: this.carga_horaria,
          val_total: this.val_total,
          qtd_editavel: this.selectedOptionRadio === "S",
          modalidadeId: this.selectedModalidade,
          categoriaId: this.selectedCategoria,
          status: "ATIVO",
        };

        await atualizarProduto(this.$route.params.id, dados);
        this.toaster.success("✅ Produto atualizado com sucesso!", { timeout: 2000 });

        this.$router.push(`/exibe-pacote/${this.$route.params.id}`);
      } catch (error) {
        console.error("❌ Erro ao atualizar produto:", error);
        this.toaster.error("⚠️ Erro ao atualizar produto.");
      } finally {
        this.submitting = false;
      }
    },
  },

  async created() {
    await this.carregarDados();
    await this.carregarPacote();
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
  },

  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },

  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  },
};
</script>
