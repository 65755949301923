<template>
  <BreadcrumbsCadColaborador :breadcrumbs="breadcrumbs" :router="$router" />

  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="cadColaborador">
                <p class="text-uppercase text-sm">Informações Básicas</p>
                <div class="row">
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Nome</label><span v-if="!nome"
                      class="error ml-2">
                      *Por favor, preencha o nome do aluno.</span>
                    <input v-model="nome" required class="form-control mb-1" type="text" placeholder="Ex: João Silva" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">E-mail</label>
                    <input v-model="email" class="form-control mb-1" type="email" placeholder="Ex: e-mail@gmail.com" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">CPF</label>
                    <span v-if="!cpf" class="error ml-2">*Por favor, preencha o CPF do aluno.</span>

                    <MaskInput v-model="cpf" class="form-control mb-1" placeholder="XXX.XXX.XXX-XX"
                      mask="###.###.###-##" />
                  </div>

                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Data de Nascimento</label>
                    <MaskInput v-model="dt_nascimento" class="form-control mb-1" placeholder="XX/XX/XXXX"
                      mask="XX/XX/XXXX" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Telefone</label>
                    <MaskInput v-model="telefone" class="form-control mb-1" placeholder="(91) 99999-9999"
                      mask="(##) #####-####" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Sexo</label>
                    <span v-if="!selectedOptionRadio" class="error ml-2">
                      *Por favor, preencha o sexo do aluno.</span>
                    <div class="combos-container">
                      <div class="combo">
                        <label>
                          <input type="radio" v-model="selectedOptionRadio" value="M" required />
                          Masculinho
                        </label>
                      </div>
                      <div class="combo">
                        <label>
                          <input type="radio" v-model="selectedOptionRadio" value="F" />
                          Feminino
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="horizontal dark" />
                <p class="text-uppercase text-sm">Dados Trabalhistas</p>
                <div class="row">
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Salário</label>
                    <money3 v-model="salario" class="form-control mb-1" v-bind="config"></money3>
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Função</label>
                    <select v-model="selectedFuncao" class="form-control" required>
                      <option disabled value="">Selecione a função</option>
                      <option v-for="funcao in funcoes" :key="funcao.id" :value="funcao.id">
                        {{ funcao.nome }}
                      </option>
                    </select>
                  </div>
                </div>
                <hr class="horizontal dark" />
                <p class="text-uppercase text-sm">Endereço</p>
                <div class="row">
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">CEP</label>
                    <MaskInput v-model="cep" class="form-control mb-1" id="cep" placeholder="XXX.XX-XXX"
                      @keyup="buscarEndereco" mask="###.##-###" />
                  </div>
                  <div class="col-md-8"></div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Logradouro</label>
                    <input v-model="logradouro" class="form-control mb-1" placeholder="Ex: Rua das Rosas" type="text"
                      id="logradouro" />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Complemento</label>
                    <input v-model="complemento" class="form-control mb-1" placeholder="Ex: 10" type="text"
                      id="complemento" />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Número</label>
                    <input v-model="numero" class="form-control mb-1" placeholder="Ex: 10" type="text" id="numero" />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Bairro</label>
                    <input v-model="bairro" class="form-control mb-1" placeholder="Ex: Canudos" type="text"
                      id="bairro" />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Cidade</label>
                    <input v-model="cidade" class="form-control mb-1" placeholder="Ex: Belém" type="text" id="cidade"
                      readonly />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Estado</label>
                    <input v-model="estado" class="form-control mb-1" placeholder="Ex: Pará" type="text" id="estado"
                      readonly />
                  </div>
                </div>
                <hr class="horizontal dark" />
                <p class="text-uppercase text-sm">Segurança</p>
                <div class="row">
                  <div class="col-md-12">
                    <label for="example-text-input" class="form-control-label">Senha</label>
                    <input v-model="senha" class="form-control mb-1" placeholder="Informe sua senha"
                      :type="showPassword ? 'text' : 'password'" id="senha" />
                    <span @click="togglePasswordVisibility" style="cursor: pointer">
                      <i class="fas" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                    </span>
                  </div>
                </div>

                <argon-button color="success" size="sm" class="ms-auto mt-2" @click="cadColaborador"
                  :disabled="submitting">Salvar</argon-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsCadColaborador from "@/components/Breadcrumbs.vue";
import { buscarCargos } from "@/api/cargo.api";
import { createToaster } from "@meforma/vue-toaster";
import ArgonButton from "@/components/ArgonButton.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { MaskInput } from "vue-3-mask";
import { cadastrarColaborador } from "@/api/colaborador.api";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "CadColaboradorComponent",
  components: {
    ArgonButton,
    MaskInput,
    BreadcrumbsCadColaborador,
  },

  data() {
    return {
      breadcrumbs: [
        { label: "Home", to: "/dashboard-auto" },
        { label: "Colaboradores", to: "/lista-colaboradores" },
      ],
      config: {
        masked: false,
        prefix: "R$ ",
        suffix: "",
        thousands: ".",
        decimal: ",",
        precision: 2,
        disableNegative: false,
        disabled: false,
        min: null,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
      },
      selectedFuncao: "",
      showMenu: false,
      nome: "",
      email: "",
      cep: "",
      logradouro: "",
      numero: "",
      bairro: "",
      complemento: "",
      cidade: "",
      estado: "",
      cpf: "",
      telefone: "",
      dt_nascimento: "",
      selectedOptionRadio: null,
      senha: "",
      showPassword: false,
    };
  },

  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    redirecionarParaListaClientes() {
      this.$router.push("/clientes");
    },
    async fetchFuncoes() {
      try {
        console.log("🔄 Buscando funções...");
        this.funcoes = await buscarCargos();
        console.log("✅ Funções recebidas:", this.funcoes);
      } catch (error) {
        console.error("❌ Erro ao buscar funções:", error);
      }
    },
    async cadColaborador() {
      const toaster = createToaster({});

      if (this.submitting) {
        return;
      }

      if (
        !this.nome ||
        !this.cpf ||
        !this.senha ||
        !this.selectedOptionRadio ||
        !this.cep ||
        !this.logradouro
      ) {
        toaster.error("Por favor, preencha todos os campos obrigatórios.", {
          timeout: 2000,
        });
        return;
      }

      if (this.cep.replace(/[^\d]/g, "").length !== 8) {
        toaster.error(
          "CEP inválido. Certifique-se de que o CEP possui 8 dígitos.",
          {
            timeout: 2000,
          }
        );
        return;
      }

      this.submitting = true;

      try {
        const token = this.$store.state.token;
        const id = this.$store.state.id_auto;

        const dados = {
          nome: this.nome,
          email: this.email,
          cpf: this.cpf.replace(/\D/g, ""),
          dt_nascimento: this.dt_nascimento,
          telefone: this.telefone,
          sexo: this.selectedOptionRadio,
          salario: this.salario,
          status: "ATIVO",
          cep: this.cep,
          logradouro: this.logradouro,
          numero: this.numero,
          bairro: this.bairro,
          roles: ["funcionario"],
          cidade: this.cidade,
          estado: this.uf,
          senha: this.senha,
          autoId: id,
          cargoId: this.selectedFuncao,
        };

        const response = await cadastrarColaborador(dados, token);

        if (response.status === 200) {
          toaster.success(response.data.message, {
            timeout: 2000,
          });

          this.$router.push("/lista-colaboradores");
        } else {
          let errorMessage = response.data.message || "Erro ao cadastrar colaborador.";
          toaster.error(errorMessage, {
            timeout: 4000,
          });
        }
      } catch (error) {
        console.error("Erro ao cadastrar colaborador:", error);
        toaster.error("Erro ao cadastrar colaborador. Tente novamente.", {
          timeout: 4000,
        });
      } finally {
        this.submitting = false;
      }
    },

    async buscarEndereco() {
      const cep = this.cep.replace(/[^\d]/g, "");

      if (cep.length === 8) {
        try {
          const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
          const data = await response.json();

          this.logradouro = data.logradouro;
          this.bairro = data.bairro;
          this.cidade = data.localidade;
          this.estado = data.uf;
        } catch (error) {
          console.error("Erro ao buscar o CEP:", error);
        }
      }
    },
  },
  created() {
    console.log("🟢 created() foi chamado.");
    this.fetchFuncoes();
  },

  mounted() {

    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  },
};
</script>
<style scoped></style>
