<template>
  <BreadcrumbsListaAlunos :breadcrumbs="breadcrumbs" :router="$router" />

  <div class="py-4 container-fluid">
    <div class="card">
      <div class="container mt-2">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 d-flex align-items-center">
            <button class="btn btn-primary btn-new" @click="cadAluno">
              Novo Aluno
            </button>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-12 d-flex align-items-center">
            <div class="d-flex w-90">
              <select class="form-control input-field" v-model="filtro">
                <option value="" disabled hidden>Selecione uma opção</option>
                <option value="nome">Nome</option>
                <option value="cpf">CPF</option>
                <option value="email">E-mail</option>
              </select>

              <input v-if="filtro === 'cpf'" type="text" class="form-control input-field" placeholder="Pesquisar"
                v-model="termoBusca" maxlength="14" @input="formataCPF" />
              <input v-else type="text" class="form-control input-field" placeholder="Pesquisar" v-model="termoBusca" />
              <button class="btn btn-primary search-btn btn-new" @click="buscarAlunos">Ir</button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-header pb-0">
        <h6>Lista de Alunos</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nome</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">CPF</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Dt.
                  Nascimento</th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="aluno in alunos" :key="aluno.id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ aluno.user.nome }}</h6>
                      <p class="text-xs text-secondary mb-0">{{ aluno.user.email }}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="mb-0 text-sm">{{ formatarCPF(aluno.cpf) }}</p>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="badge badge-sm bg-gradient-success">{{ aluno.status }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"> {{ formatarData(aluno.dt_nascimento) }}
                  </span>
                </td>
                <td class="align-middle">
                  <a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip"
                    data-original-title="Edit user">
                    Visualizar
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { buscarAlunos } from "@/api/aluno.api";
import BreadcrumbsListaAlunos from "@/components/Breadcrumbs.vue";

export default {

  components: { BreadcrumbsListaAlunos },

  data() {
    return {
      breadcrumbs: [{ label: "Home", to: "/dashboard-auto" }],
      alunos: [],
      filtro: "",
      termoBusca: "",
    };
  },

  mounted() {
    this.filtro = this.$route.query.filtro || "";
    this.termoBusca = this.$route.query.termo || "";
    this.fetchAlunos();
  },

  methods: {
    formataCPF(event) {
      let valor = event.target.value;
      valor = valor.replace(/\D/g, "");
      if (valor.length > 11) {
        valor = valor.slice(0, 11);
      }
      if (valor.length <= 11) {
        valor = valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      this.termoBusca = valor;
    },
    formatarData(data) {
      if (!data || data.length !== 8) return "";
      return data.replace(/^(\d{2})(\d{2})(\d{4})$/, "$1/$2/$3");
    },
    formatarCPF(cpf) {
      if (!cpf) return "";
      return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
    },
    async fetchAlunos() {
      try {
        const idAuto = this.$store.state.id_auto;
        const params = {};

        if (this.filtro && this.termoBusca) {
          params[this.filtro] = this.termoBusca;
        }

        this.alunos = await buscarAlunos(idAuto, params);
      } catch (error) {
        console.error("Erro ao buscar alunos:", error);
      }
    },

    buscarAlunos() {
      const { filtro, termoBusca } = this;
      const queryParams = filtro && termoBusca ? { filtro, termo: termoBusca } : {};

      this.$router.push({ path: "/alunos", query: queryParams });
      this.fetchAlunos();
    },

    cadAluno() {
      this.$router.push("/cad-alunos");
    },
  },
};
</script>
