<template>
  <BreadcrumbsCadCargo :breadcrumbs="breadcrumbs" :router="$router" />

  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="cadCargo">
                <div class="row">
                  <div class="col-md-12">
                    <label for="example-text-input" class="form-control-label">Nome</label>
                    <input required v-model="nome" class="form-control mb-1" type="text"
                      placeholder="Ex: Analista de Atendimento" />
                  </div>
                </div>

                <argon-button color="success" size="sm" class="ms-auto mt-2" @click="cadCargo"
                  :disabled="submitting">Salvar</argon-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { createToaster } from "@meforma/vue-toaster";
import { cadastrarCargo } from "@/api/cargo.api";
import ArgonButton from "@/components/ArgonButton.vue";
import BreadcrumbsCadCargo from "@/components/Breadcrumbs.vue";

export default {
  name: "CadCargoComponent",
  components: { ArgonButton, BreadcrumbsCadCargo },

  data() {
    return {
      breadcrumbs: [
        { label: "Home", to: "/dashboard-admin" },
        { label: "Lista Cargos", to: "/lista-cargos" },
      ],
      nome: "",
      submitting: false,
    };
  },

  methods: {
    async cadCargo() {
      if (!this.nome || this.submitting) return;
      this.submitting = true;
      const toaster = createToaster({});

      try {
        await cadastrarCargo(this.nome);
        toaster.success("Cargo cadastrado com sucesso!", { timeout: 2000 });
        this.$router.push("/lista-cargos");
      } catch (error) {
        toaster.error("Erro ao cadastrar cargo!", { timeout: 4000 });
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>
