import api from "@/api/api";

export async function buscarAutoEscolas() {
  const response = await api.get("/admin/buscaAutoEscola");
  return response.data.data;
}

export async function buscarAutoEscolaPorId(id) {
  const response = await api.get(`/admin/buscaAutoPorID/${id}`);
  return response.data.data[0];
}

export async function cadastrarAutoEscola(dados) {
  const response = await api.post("/usuario/novoUsuarioAutoEscola", dados);
  return response.data;
}

export async function atualizarAutoEscola(id, dados) {
  const response = await api.put(`/admin/alteraAutoPorId/${id}`, dados);
  return response.data;
}
