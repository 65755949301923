<template>
  <BreadcrumbsCadAluno :breadcrumbs="breadcrumbs" :router="$router" />

  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="cadAluno">
                <p class="text-uppercase text-sm">Informações Básicas</p>
                <div class="row">
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Nome</label><span v-if="!nome"
                      class="error ml-2">
                      *Por favor, preencha o nome do aluno.</span>
                    <input v-model="nome" required class="form-control mb-1" type="text" placeholder="Ex: João Silva" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">E-mail</label>
                    <input v-model="email" class="form-control mb-1" type="email" placeholder="Ex: e-mail@gmail.com" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">CPF</label>
                    <span v-if="!cpf" class="error ml-2">*Por favor, preencha o CPF do aluno.</span>

                    <MaskInput v-model="cpf" class="form-control mb-1" placeholder="XXX.XXX.XXX-XX"
                      mask="###.###.###-##" />
                  </div>

                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Data de Nascimento</label>
                    <MaskInput v-model="dt_nascimento" class="form-control mb-1" placeholder="XX/XX/XXXX"
                      mask="XX/XX/XXXX" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Telefone</label>
                    <MaskInput v-model="telefone" class="form-control mb-1" placeholder="(91) 99999-9999"
                      mask="(##) #####-####" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Sexo</label>
                    <span v-if="!selectedOptionRadio" class="error ml-2">
                      *Por favor, preencha o sexo do aluno.</span>
                    <div class="combos-container">
                      <div class="combo">
                        <label>
                          <input type="radio" v-model="selectedOptionRadio" value="M" required />
                          Masculinho
                        </label>
                      </div>
                      <div class="combo">
                        <label>
                          <input type="radio" v-model="selectedOptionRadio" value="F" />
                          Feminino
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="horizontal dark" />
                <p class="text-uppercase text-sm">Endereço</p>
                <div class="row">
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">CEP</label>
                    <MaskInput v-model="cep" class="form-control mb-1" id="cep" placeholder="XXX.XX-XXX"
                      @keyup="buscarEndereco" mask="###.##-###" />
                  </div>
                  <div class="col-md-8"></div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Logradouro</label>
                    <input v-model="logradouro" class="form-control mb-1" placeholder="Ex: Rua das Rosas" type="text"
                      id="logradouro" />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Complemento</label>
                    <input v-model="complemento" class="form-control mb-1" placeholder="Ex: 10" type="text"
                      id="complemento" />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Número</label>
                    <input v-model="numero" class="form-control mb-1" placeholder="Ex: 10" type="text" id="numero" />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Bairro</label>
                    <input v-model="bairro" class="form-control mb-1" placeholder="Ex: Canudos" type="text"
                      id="bairro" />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Cidade</label>
                    <input v-model="cidade" class="form-control mb-1" placeholder="Ex: Belém" type="text" id="cidade"
                      readonly />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Estado</label>
                    <input v-model="estado" class="form-control mb-1" placeholder="Ex: Pará" type="text" id="estado"
                      readonly />
                  </div>
                </div>

                <argon-button color="success" size="sm" class="ms-auto mt-2"
                  :disabled="submitting">Salvar</argon-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { cadastrarAluno } from "@/api/aluno.api";
import BreadcrumbsCadAluno from "@/components/Breadcrumbs.vue";
import { createToaster } from "@meforma/vue-toaster";
import ArgonButton from "@/components/ArgonButton.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { MaskInput } from "vue-3-mask";
const body = document.getElementsByTagName("body")[0];

export default {
  name: "CadAlunoComponent",
  components: { ArgonButton, MaskInput, BreadcrumbsCadAluno },

  data() {
    return {
      breadcrumbs: [
        { label: "Home", to: "/dashboard-auto" },
        { label: "Alunos", to: "/alunos" },
      ],
      showMenu: false,
      nome: "",
      email: "",
      cep: "",
      logradouro: "",
      numero: "",
      bairro: "",
      complemento: "",
      cidade: "",
      estado: "",
      cpf: "",
      telefone: "",
      dt_nascimento: "",
      selectedOptionRadio: null,
    };
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    redirecionarParaListaClientes() {
      this.$router.push("/clientes");
    },
    async cadAluno() {
      const toaster = createToaster({});

      if (this.submitting) {
        return;
      }

      this.submitting = true;

      const id = this.$store.state.id_auto;

      const dados = {
        nome: this.nome,
        email: this.email,
        cpf: this.cpf.replace(/\D/g, ""),
        dt_nascimento: this.dt_nascimento,
        telefone: this.telefone,
        sexo: this.selectedOptionRadio,
        status: "ATIVO",
        cep: this.cep,
        logradouro: this.logradouro,
        numero: this.numero,
        bairro: this.bairro,
        roles: ["alunos"],
        cidade: this.cidade,
        uf: this.uf,
        auto_id: id,
      };

      try {
        const response = await cadastrarAluno(dados);
        console.log("Resposta:", response);
        toaster.success("Cliente cadastrado com sucesso!", {
          timeout: 2000,
        });

        this.$router.push("/alunos");
      } catch (error) {
        if (error.response) {
          toaster.error(error.response.data.message, { timeout: 4000 });
        } else if (error.request) {
          toaster.error("Erro ao processar a requisição. Tente novamente.", {
            timeout: 4000,
          });
        } else {
          toaster.error("Ocorreu um erro inesperado. Tente novamente.", {
            timeout: 4000,
          });
        }
      } finally {
        this.submitting = false;
      }
    }
    ,
    async buscarEndereco() {
      const cep = this.cep.replace(/[^\d]/g, "");

      if (cep.length === 8) {
        try {
          const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
          const data = await response.json();

          this.logradouro = data.logradouro;
          this.bairro = data.bairro;
          this.cidade = data.localidade;
          this.estado = data.uf;
        } catch (error) {
          console.error("Erro ao buscar o CEP:", error);
        }
      }
    },
  },

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  },
};
</script>
