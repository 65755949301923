<template>
  <BreadcrumbsCategoria :breadcrumbs="breadcrumbs" :router="$router" />

  <div class="py-4 container-fluid">
    <div class="card">
      <div class="container mt-2">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 d-flex align-items-center">
            <button class="btn btn-primary btn-new" @click="cadCategoria">
              Nova Categoria
            </button>
          </div>
        </div>
      </div>

      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <v-breadcrumbs bg-color="primary" :items="['Foo', 'Bar', 'Fizz']"></v-breadcrumbs>
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Nome
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="categoria in categorias" :key="categoria.id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ categoria.nome }}</h6>
                    </div>
                  </div>
                </td>
                <td class="align-right">
                  <a href="javascript:;" class="text-primary font-weight-bold text-xs"
                    @click="editarCategoria(categoria.id)" data-toggle="tooltip"
                    data-original-title="Abrir categoria">Editar</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { buscarCategorias } from "@/api/categoria.api";
import BreadcrumbsCategoria from "@/components/Breadcrumbs.vue";
import { createToaster } from "@meforma/vue-toaster";

export default {
  components: { BreadcrumbsCategoria },
  name: "ListaCategoriaComponent",

  data() {
    return {
      breadcrumbs: [{ label: "Home", to: "/dashboard-admin" }],
      categorias: [],
      toaster: createToaster({}),
    };
  },

  mounted() {
    this.carregarCategorias();
  },

  methods: {
    async carregarCategorias() {
      try {
        this.categorias = await buscarCategorias();
        console.log("--- CATEGORIAS ---", this.categorias);
      } catch (error) {
        console.error("❌ Erro ao buscar categorias:", error);
        this.toaster.error("⚠️ Erro ao carregar categorias. Tente novamente mais tarde.");
      }
    },

    editarCategoria(id) {
      this.$router.push({ path: `/edita-categoria/${id}` });
    },

    cadCategoria() {
      this.$router.push("/cad-categoria");
    },
  },
};
</script>

<style scoped>
.input-field,
.search-btn,
.btn-new {
  height: 38px;
  border-radius: 0;
}

.select-field {
  width: 40px;
}

.search-input {
  flex-grow: 1;
}
</style>
