<template>
  <BreadcrumbsAuto :breadcrumbs="breadcrumbs" :router="$router" />

  <div class="py-4 container-fluid">
    <div class="row mb-4">
      <div class="col-md-2 mt-1">
        <label for="period">Selecione o período:</label>
      </div>
      <div class="col-md-4">
        <select id="period" v-model="selectedPeriod" @change="reloadPage" class="form-control">
          <option value="all">Todo o período</option>
          <option value="30">30 dias</option>
          <option value="60">60 dias</option>
          <option value="90">90 dias</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12">
            <card :title="stats.money.title" :value="stats.money.value" :percentage="stats.money.percentage"
              :iconClass="stats.money.iconClass" :iconBackground="stats.money.iconBackground"
              :detail="stats.money.detail" directionReverse></card>
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <card :title="stats.users.title" :value="stats.users.value" :percentage="stats.users.percentage"
              :iconClass="stats.users.iconClass" :iconBackground="stats.users.iconBackground"
              :detail="stats.users.detail" directionReverse></card>
          </div>

          <div class="col-lg-4 col-md-6 col-12">
            <card :title="stats.sales.title" :value="stats.sales.value" :percentage="stats.sales.percentage"
              :iconClass="stats.sales.iconClass" :iconBackground="stats.sales.iconBackground"
              :detail="stats.sales.detail" directionReverse></card>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7 mb-lg">
            <div class="card z-index-2">
              <gradient-line-chart />
            </div>
          </div>
          <div class="col-lg-5">
            <carousel />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Card from "@/widgets/Cards/Card.vue";
import GradientLineChart from "@/widgets/Charts/GradientLineChart.vue";
import Carousel from "./components/Carousel.vue";
import BreadcrumbsAuto from "@/components/Breadcrumbs.vue";
import { fetchQtdColaborador } from "@/api/colaborador.api";
import { fetchQtdAlunos } from "@/api/aluno.api";

export default {
  props: {},
  name: "dashboard-auto",
  data() {
    return {
      alunos: [],
      colaborador: [],
      vendas: [],
      selectedPeriod: "all",
      breadcrumbs: [{ label: "Home", to: "/dashboard-auto" }],
      stats: {
        money: {
          title: "Alunos",
          value: "",
          iconClass: "ni ni-circle-08",
          iconBackground: "bg-gradient-primary",
        },
        users: {
          title: "Colaboradores",
          value: "",
          iconClass: "ni ni-single-02",
          iconBackground: "bg-gradient-danger",
        },
        sales: {
          title: "Vendas Realizadas",
          value: "",
          iconClass: "ni ni-cart",
          iconBackground: "bg-gradient-warning",
        },
      },
    };
  },
  methods: {
    async fetchData() {
      await this.fetchQtdAlunos(this.selectedPeriod);
      await this.fetchQtdColaborador(this.selectedPeriod);
      await this.fetchQtdVendas(this.selectedPeriod);
    },
    logout() {
      this.$store.commit("clearAuth");
      this.$router.push("/login");
    },
    async fetchQtdVendas(period) {
      const token = this.$store.state.token;
      const id = this.$store.state.id_auto;

      console.log(token);
      try {
        const response = await axios.get(
          this.$baseURL +
          `/cobranca/buscaQtdCobrancaAutoEscola/${id}?period=${period}`,
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.vendas = response.data.data;
        this.stats.sales.value = this.vendas;
      } catch (error) {
        console.error("Erro ao buscar colaborador :", error);
      }
    },

    async fetchQtdColaborador(period) {
      const id = this.$store.state.id_auto;
      try {
        const colaboradores = await fetchQtdColaborador(id, period);
        this.colaborador = colaboradores;
        this.stats.users.value = this.colaborador;
      } catch (error) {
        console.error("Erro ao buscar colaborador:", error);
      }
    },

    async fetchQtdAlunos(period) {
      const id = this.$store.state.id_auto;

      try {
        const alunos = await fetchQtdAlunos(id, period);
        this.alunos = alunos;
        this.stats.money.value = this.alunos;
      } catch (error) {
        console.error("Erro ao buscar alunos:", error);
      }
    },
    watch: {
      selectedPeriod(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.fetchData();
        }
      },
    },
  },
  mounted() {
    this.fetchData();
  },
  components: {
    BreadcrumbsAuto,
    Card,
    GradientLineChart,
    Carousel,
  },
};
</script>
