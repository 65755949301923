<template>
  <BreadcrumbsAdmin :breadcrumbs="breadcrumbs" :router="$router" />

  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-7 mb-lg">
            <div class="card z-index-2">
              <gradient-line-chart />
            </div>
          </div>
          <div class="col-lg-5">
            <carousel />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GradientLineChart from "@/widgets/Charts/GradientLineChart.vue";
import Carousel from "./components/Carousel.vue";
import BreadcrumbsAdmin from "@/components/Breadcrumbs.vue";

export default {
  name: "dashboard-default",
  data() {
    return {
      alunos: [],
      colaborador: [],
      vendas: [],
      selectedPeriod: "all",
      breadcrumbs: [{ label: "Home", to: "/dashboard-admin" }],
      stats: {
        money: {
          title: "Alunos",
          value: "",
          iconClass: "ni ni-circle-08",
          iconBackground: "bg-gradient-primary",
        },
        users: {
          title: "Colaboradores",
          value: "24",
          iconClass: "ni ni-single-02",
          iconBackground: "bg-gradient-danger",
        },
        sales: {
          title: "Vendas Realizadas",
          value: "",
          iconClass: "ni ni-cart",
          iconBackground: "bg-gradient-warning",
        },
      },
    };
  },
  components: {
    GradientLineChart,
    Carousel,
    BreadcrumbsAdmin,
  },
};
</script>
