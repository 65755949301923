import api from "@/api/api";

export async function cadastrarPacote(dados) {
  const response = await api.post("/produto/novoProduto", dados);
  return response.data;
}

export async function buscarProdutos() {
  const response = await api.get("/produto/buscaProduto");
  return response.data.data;
}

export async function buscarProdutoPorId(id) {
  const response = await api.get(`/produto/buscaProdutoPorID/${id}`);
  return response.data.data;
}

export async function buscarProdutosAutoEscola(id_user) {
  const response = await api.get(`/produto/buscaProdutosAutoEscola/${id_user}`);
  return response.data.data;
}

export async function atualizarProduto(id, dados) {
  const response = await api.put(`/produto/editarProduto/${id}`, dados);
  return response.data;
}
