<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow" v-bind:darkMode="true"
          isBtn="bg-gradient-success" />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4">
              <div class="card card-plain">
                <img src="../assets/img/logos/logo-paycfc.png" alt="Logo PayCFC" style="width: 300px" />
                <div class="pb-0 card-header text-start">
                  <label class="font-weight-light mb-0">
                    Informe seu e-mail registrado para <b>resetar</b> a sua
                    senha!
                  </label>
                </div>
                <div class="card-body">
                  <form @submit.prevent="Reset">
                    <div>
                      <input v-model="email" class="form-control" placeholder="E-mail" type="email" id="email"
                        required />
                    </div>

                    <div class="text-center">
                      <argon-button class="mt-2" variant="gradient" color="warning" fullWidth size="lg" type="submit"
                        :disabled="submitting" @click="Reset">
                        Enviar nova senha
                      </argon-button>
                    </div>
                  </form>
                  <label><router-link to="/login">Voltar ao login</router-link>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
const body = document.getElementsByTagName("body")[0];

export default {
  name: "reset_comp",
  components: {
    ArgonButton,
  },
  data() {
    return {
      email: "",
    };
  },
  methods: {
    async Reset() {
      const toaster = createToaster({});

      if (this.submitting) {
        return;
      }

      if (!this.email) {
        toaster.error("Por favor, preencha o e-mail", {
          timeout: 2000,
        });
        return;
      }

      this.submitting = true;

      try {
        const dados = {
          email: this.email,
        };

        try {
          const response = await axios.post(
            this.$baseURL + "/auth/esqueci_senha",
            dados
          );

          if (response.status === 200) {
            toaster.success("A nova senha foi enviada ao e-mail cadastrado", {
              timeout: 2000,
            });

            this.$router.push("/login");
          } else {
            console.log(response.data.message);
            toaster.success(response.data.message, {
              timeout: 4000,
            });
          }
        } catch (error) {
          console.log(error);
          console.error("Error:", error);
        }
      } finally {
        this.submitting = false;
      }
    },
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
