<template>
  <BreadcrumbsCadCategoria :breadcrumbs="breadcrumbs" :router="$router" />

  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="saveCategoria">
                <div class="row">
                  <div class="col-md-12">
                    <label for="example-text-input" class="form-control-label">Nome</label>
                    <input v-model="nome" required class="form-control mb-1" type="text"
                      placeholder="Ex: Categoria A" />
                  </div>
                </div>

                <argon-button color="success" size="sm" class="ms-auto mt-2" @click="saveCategoria"
                  :disabled="submitting">Salvar</argon-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { buscarCategoriaPorId, atualizarCategoria } from "@/api/categoria.api"; // ✅ Importando API
import { createToaster } from "@meforma/vue-toaster";
import ArgonButton from "@/components/ArgonButton.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import BreadcrumbsCadCategoria from "@/components/Breadcrumbs.vue";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "EditaCategoriaComponent",
  components: { ArgonButton, BreadcrumbsCadCategoria },

  data() {
    return {
      breadcrumbs: [
        { label: "Home", to: "/dashboard-admin" },
        { label: "Lista Categorias", to: "/lista-categoria" },
        { label: "Editar Categoria", to: this.$route.path },
      ],

      nome: "",
      submitting: false,
      categoriaId: null,
    };
  },

  methods: {
    async loadCategoria() {
      const id = this.$route.params.id;
      if (id) {
        try {
          const categoria = await buscarCategoriaPorId(id);
          this.nome = categoria.nome;
          this.categoriaId = id;
        } catch (error) {
          console.error("❌ Erro ao carregar categoria:", error);
        }
      }
    },

    async saveCategoria() {
      if (!this.nome || this.submitting) {
        return;
      }
      this.submitting = true;

      try {
        const toaster = createToaster({});

        const dados = { nome: this.nome };

        await atualizarCategoria(this.categoriaId, dados);

        toaster.success("Categoria salva com sucesso!", {
          timeout: 2000,
        });

        this.$router.push("/lista-categoria");
      } catch (error) {
        console.error("❌ Erro ao salvar categoria:", error);
        const toaster = createToaster({});
        toaster.error("Erro ao salvar categoria!", { timeout: 4000 });
      } finally {
        this.submitting = false;
      }
    },
  },

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
    this.loadCategoria();
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },

  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  },
};
</script>
