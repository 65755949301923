<template>
  <BreadcrumbsCadCargo :breadcrumbs="breadcrumbs" :router="$router" />

  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="saveCargo">
                <div class="row">
                  <div class="col-md-12">
                    <label for="example-text-input" class="form-control-label">Nome</label>
                    <input v-model="nome" required class="form-control mb-1" type="text"
                      placeholder="Ex: Analista de Atendimento" />
                  </div>
                </div>

                <argon-button color="success" size="sm" class="ms-auto mt-2" @click="saveCargo"
                  :disabled="submitting">Salvar</argon-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { createToaster } from "@meforma/vue-toaster";
import { buscarCargoPorId, atualizarCargo } from "@/api/cargo.api";
import ArgonButton from "@/components/ArgonButton.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import BreadcrumbsCadCargo from "@/components/Breadcrumbs.vue";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "EditarCargoComponent",
  components: { ArgonButton, BreadcrumbsCadCargo },

  data() {
    return {
      breadcrumbs: [
        { label: "Home", to: "/dashboard-admin" },
        { label: "Lista Cargos", to: "/lista-cargo" },
        { label: "Editar Cargo", to: this.$route.path },
      ],
      nome: "",
      submitting: false,
      cargoId: null,
    };
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },

    async loadCargo() {
      const id = this.$route.params.id;
      if (id) {
        try {
          const cargo = await buscarCargoPorId(id);
          this.nome = cargo.nome;
          this.cargoId = id;
        } catch (error) {
          console.error("Erro ao carregar cargo:", error);
        }
      }
    },

    async saveCargo() {
      if (!this.nome || this.submitting) {
        return;
      }
      this.submitting = true;

      try {
        const toaster = createToaster({});
        const dados = { nome: this.nome };

        const response = await atualizarCargo(this.cargoId, dados);

        if (response.status === 200) {
          toaster.success("Cargo salvo com sucesso!", { timeout: 2000 });
          this.$router.push("/lista-cargos");
        }
      } catch (error) {
        console.error("Erro ao salvar cargo:", error);
      } finally {
        this.submitting = false;
      }
    },
  },

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
    this.loadCargo();
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },

  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  },
};
</script>
