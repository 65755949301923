<template>
  <div class="d-flex justify-content-end p-3 fixed-top w-100 bg-transparent">
    <button class="btn btn-light text-danger btn-sm" @click="logout">
      Sair
    </button>
  </div>

  <div v-show="this.$store.state.layout === 'default'" class="min-height-100 position-absolute w-100"
    :class="`${this.$store.state.darkMode ? 'bg-transparent' : 'bg-warning'}`" />

  <aside class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl" :class="`${this.$store.state.isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'} 
            ${this.$store.state.layout === 'landing' ? 'bg-transparent shadow-none' : ' '} 
            ${this.$store.state.sidebarType}`" id="sidenav-main">

    <div class="sidenav-header">
      <i class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true" id="iconSidenav"></i>
      <router-link class="m-0 navbar-brand" to="/dashboard-admin">
        <img :src="this.$store.state.darkMode || this.$store.state.sidebarType === 'bg-default'
      ? logoWhite
      : logo" class="navbar-brand-img h-100" alt="main_logo" />
        <span class="ms-2 font-weight-bold me-2">payCFC</span>
      </router-link>
    </div>

    <hr class="mt-0 horizontal dark" />
    <sidenav-list :cardBg="custom_class" />
  </aside>
</template>

<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo-paycfc-dark.png";
import logoWhite from "@/assets/img/logo-paycfc.png";
import { logout } from "@/api/auth.api";
import store from "@/store";

export default {
  name: "IndexComponent",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
      logoWhite,
    };
  },
  methods: {
    async logout() {

      try {
        store.commit("clearAuth");
        store.commit("setIsAuthenticated", false);
        store.commit("setUserRole", null);
        store.commit("setToken", null);
        store.commit("setIdAuto", null);
        store.commit("setIdUsuario", null);
        store.commit("setCodEfi", null);
        store.commit("setShowSidenav", false);

        localStorage.removeItem("authToken");
        localStorage.removeItem("userRole");
        localStorage.removeItem("idAuto");
        localStorage.removeItem("idUsuario");
        localStorage.removeItem("codEfi");

        await logout();

        store.commit("setLogoutFlag", true);
        this.$router.push("/login");
      } catch (error) {
        console.error("❌ Erro ao fazer logout:", error);
      }
    },
  },
};
</script>
