<template>
  <div class="collapse navbar-collapse w-auto h-auto h-100" id="sidenav-collapse-main">
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item url="/dashboard-auto" :class="getRoute() === 'dashboard-auto' ? 'active' : ''"
          :navText="this.$store.state.isRTL ? 'لوحة القيادة' : 'Dashboard'">
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item url="/alunos" :class="getRoute() === 'alunos' ? 'active' : ''"
          :navText="this.$store.state.isRTL ? 'الجداول' : 'Alunos'">
          <template v-slot:icon>
            <i class="ni ni-badge text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item url="/lista-colaboradores" :class="getRoute() === '/lista-colaboradores' ? 'active' : ''"
          :navText="this.$store.state.isRTL ? 'الواقع الافتراضي' : 'Colaboradores'
          ">
          <template v-slot:icon>
            <i class="ni ni-circle-08 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <hr class="horizontal dark" />

      <h6 class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6 mt-3"
        :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'">
        Financeiro
      </h6>
      <li class="nav-item">
        <sidenav-item url="/gerar-cobranca" :class="getRoute() === 'billing' ? 'active' : ''"
          :navText="this.$store.state.isRTL ? 'الفواتیر' : 'Gerar Cobrança'">
          <template v-slot:icon>
            <i class="ni ni-cart text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item url="/lista-cobranca" :class="getRoute() === 'rtl-page' ? 'active' : ''" navText="Movimentação">
          <template v-slot:icon>
            <i class="ni ni-chart-bar-32 text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="mt-3 nav-item">
        <hr class="horizontal dark" />

        <h6 v-if="this.$store.state.isRTL" class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'">
          صفحات المرافق
        </h6>

        <h6 v-else class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'">
          Minha Conta
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-item url="/alterar-senha" :class="getRoute() === 'profile' ? 'active' : ''"
          :navText="this.$store.state.isRTL ? 'حساب تعريفي' : 'Perfil'">
          <template v-slot:icon>
            <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
    </ul>
  </div>
  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card :class="cardBg" textPrimary="Need Help?" textSecondary="Please check our docs" />
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCard from "./SidenavCard.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Argon Dashboard 2",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  components: {
    SidenavItem,
    SidenavCard,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
