<template>
  <BreadcrumbsExibePacote :breadcrumbs="breadcrumbs" :router="$router" />

  <div class="py-4 container-fluid">
    <div class="card">
      <h1>Detalhes do Pacote</h1>
      <div v-if="pacote">
        <p><strong>Curso:</strong> {{ pacote.curso }}</p>
        <p><strong>UPF:</strong> {{ pacote.upf }}</p>
        <p><strong>Valor Unitário:</strong> R$ {{ pacote.val_unitario }}</p>
        <p><strong>Carga Horária:</strong> {{ pacote.carga_horaria }} horas</p>
        <p><strong>Valor Total:</strong> R$ {{ pacote.val_total }}</p>
        <p><strong>Status:</strong> {{ pacote.status }}</p>
        <p><strong>Modalidade:</strong> {{ pacote.modalidade?.nome || 'N/A' }}</p>
        <p><strong>Categoria:</strong> {{ pacote.categoria?.nome || 'N/A' }}</p>
        <button @click="editarPacote" class="btn btn-primary">Editar</button>
      </div>
      <div v-else>
        <p>Não foi possível carregar os detalhes do pacote.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { buscarProdutoPorId } from "@/api/pacote.api";
import { createToaster } from "@meforma/vue-toaster";
import BreadcrumbsExibePacote from "@/components/Breadcrumbs.vue";

export default {
  components: { BreadcrumbsExibePacote },

  data() {
    return {
      breadcrumbs: [
        { label: "Home", to: "/dashboard-admin" },
        { label: "Lista de Pacotes", to: "/lista-pacotes" },
      ],
      pacote: null,
      toaster: createToaster({}),
    };
  },

  async created() {
    await this.buscarPacote();
  },

  methods: {
    async buscarPacote() {
      try {
        const id = this.$route.params.id;
        console.log("🔍 Buscando pacote com ID:", id);

        const response = await buscarProdutoPorId(id);
        console.log("✅ Resposta da API:", response);

        // Se a API retorna um array, pegamos o primeiro item
        this.pacote = Array.isArray(response) ? response[0] : response;

        if (!this.pacote) {
          this.toaster.error("⚠️ Pacote não encontrado.");
        }
      } catch (error) {
        console.error("❌ Erro ao buscar pacote:", error);
        this.toaster.error("⚠️ Erro ao buscar pacote. Tente novamente.");
      }
    },

    editarPacote() {
      const id = this.$route.params.id;
      this.$router.push(`/edita-pacote/${id}`);
    },
  },
};
</script>
