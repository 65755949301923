import axios from "axios";
import store from "@/store";
import router from "@/router";

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: true,
});

api.interceptors.request.use(
  (config) => {
    config.withCredentials = true;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const { status } = error.response;

      if (status === 401) {
        console.warn("⛔ Sessão expirada. Redirecionando para login...");
        store.commit("clearAuth");
        router.push("/login");
      } else if (status === 403) {
        console.warn("🚫 Acesso negado.");
        router.push("/acesso-negado");
      } else if (status === 500) {
        console.error("🔥 Erro interno do servidor.");
      }
    } else {
      console.error("🚨 Erro de conexão com a API.");
    }
    return Promise.reject(error);
  }
);

export default api;
