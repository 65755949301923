import { createRouter, createWebHistory } from "vue-router";
import Tables from "../views/Tables.vue";
import AlterarSenha from "../views/AlterarSenha.vue";
import Signup from "../views/Signup.vue";
import Login from "../views/Login.vue";
import ResetSenha from "../views/ResetSenha.vue";
import autoRoutes from "../domain/auto_escola/routes";
import adminRoutes from "@/domain/admin/routes";
import colaboradorRoutes from "@/domain/colaborador/routes";
import store from "@/store";

const routes = [
  { path: "/:pathMatch(.*)*", redirect: "/login" }, // Rota padrão para login
  ...autoRoutes,
  ...adminRoutes,
  ...colaboradorRoutes,
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/alterar-senha",
    name: "AlterarSenha",
    component: AlterarSenha,
  },
  {
    path: "/reset-senha",
    name: "ResetSenha",
    component: ResetSenha,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiresAuth: false }, // Rota pública
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: { requiresAuth: false }, // Rota pública
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active", // Classe CSS para links ativos
});

router.beforeEach(async (to, from, next) => {
  // Verifica se a rota requer autenticação
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  // Se a rota não requer autenticação, permite o acesso
  if (!requiresAuth) {
    next();
    return;
  }

  // Verifica se o usuário está autenticado
  const isAuthenticated = store.state.isAuthenticated;

  if (!isAuthenticated) {
    // Se não estiver autenticado, redireciona para o login com o caminho original
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
    return;
  }

  // Verifica se a rota requer papéis específicos
  const requiredRoles = to.meta.roles;
  const userRole = store.state.userRole;

  if (requiredRoles && !requiredRoles.includes(userRole)) {
    // Se o usuário não tem o papel necessário, redireciona para uma página de acesso negado
    console.log("🚫 Acesso negado!");
    next("/unauthorized");
    return;
  }

  // Se tudo estiver correto, permite o acesso à rota
  next();
});

export default router;
