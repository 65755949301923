<template>
  <div v-show="layout === 'landing'" class="landing-bg h-80 bg-gradient-primary position-fixed w-40"></div>

  <!-- Renderiza o Sidenav com base no papel do usuário -->
  <template v-if="isAuthenticated && showSidenav">
    <component :is="sidenavComponent" :custom_class="mcolor" :class="isTransparent" />
  </template>

  <main class="main-content position-relative max-height-vh-100 h-80 border-radius-lg">
    <!-- Renderiza o Sidenav novamente (se necessário) -->
    <template v-if="showNavbar && isAuthenticated">
      <component :is="sidenavComponent" />
    </template>

    <router-view />

    <!-- Renderiza o footer -->
    <app-footer v-show="showFooter" />

    <!-- Renderiza o configurator para administradores -->
    <configurator v-if="isAuthenticated && userRole === 'ROLE_ADMIN'" :toggle="toggleConfigurator"
      :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']" />
  </main>
</template>

<script>
import SidenavAuto from "./domain/auto_escola/components/Sidenav";
import SidenavAdmin from "./domain/admin/components/Sidenav";
import SidenavColaborador from "./domain/colaborador/components/Sidnav/index.vue";
import AppFooter from "@/widgets/Footer.vue";
import { mapMutations, mapState } from "vuex";

export default {
  name: "App",
  components: {
    SidenavAuto,
    SidenavAdmin,
    SidenavColaborador,
    AppFooter,
  },
  computed: {
    // Mapeia o estado do Vuex
    ...mapState([
      "layout",
      "mcolor",
      "isTransparent",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
      "isAuthenticated",
      "userRole",
    ]),

    // Define o componente Sidenav com base no papel do usuário
    sidenavComponent() {
      if (this.userRole === "ROLE_AUTO_ESCOLA") {
        return SidenavAuto;
      } else if (this.userRole === "ROLE_FUNCIONARIO") {
        return SidenavColaborador;
      } else if (this.userRole === "ROLE_ADMIN") {
        return SidenavAdmin;
      }
      return null;
    },
  },
  methods: {
    // Mapeia as mutations do Vuex
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
};
</script>