<template>
  <BreadcrumbsCadModalidade :breadcrumbs="breadcrumbs" :router="$router" />

  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="saveModalidade">
                <div class="row">
                  <div class="col-md-12">
                    <label for="example-text-input" class="form-control-label">Nome</label>
                    <input v-model="nome" required class="form-control mb-1" type="text" placeholder="Ex: 1 CNH" />
                  </div>
                </div>

                <argon-button color="success" size="sm" class="ms-auto mt-2" @click="saveModalidade"
                  :disabled="submitting">Salvar</argon-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { buscarModalidadePorId, atualizarModalidade } from "@/api/modalidade.api";
import { createToaster } from "@meforma/vue-toaster";
import ArgonButton from "@/components/ArgonButton.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import BreadcrumbsCadModalidade from "@/components/Breadcrumbs.vue";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "EditaModalidadeComponent",
  components: { ArgonButton, BreadcrumbsCadModalidade },

  data() {
    return {
      breadcrumbs: [
        { label: "Home", to: "/dashboard-admin" },
        { label: "Lista Modalidades", to: "/lista-modalidade" },
        { label: "Editar Modalidade", to: this.$route.path },
      ],
      nome: "",
      submitting: false,
      modalidadeId: null,
      toaster: createToaster({}), // ✅ Criando toaster para evitar múltiplas instâncias
    };
  },

  methods: {
    async loadModalidade() {
      const id = this.$route.params.id;
      if (!id) return;

      try {
        const modalidade = await buscarModalidadePorId(id);
        this.nome = modalidade.nome;
        this.modalidadeId = id;
      } catch (error) {
        this.toaster.error("⚠️ Erro ao carregar modalidade. Tente novamente.");
      }
    },

    async saveModalidade() {
      if (!this.nome || this.submitting) return;

      this.submitting = true;

      try {
        const dados = { nome: this.nome };
        await atualizarModalidade(this.modalidadeId, dados);

        this.toaster.success("✅ Modalidade salva com sucesso!", { timeout: 2000 });
        this.$router.push("/lista-modalidade");
      } catch (error) {
        this.toaster.error("⚠️ Erro ao salvar modalidade. Tente novamente.");
      } finally {
        this.submitting = false;
      }
    },
  },

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
    this.loadModalidade();
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },

  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  },
};
</script>
