<template>
  <BreadcrumbsCargos :breadcrumbs="breadcrumbs" :router="$router" />

  <div class="py-4 container-fluid">
    <div class="card">
      <div class="container mt-2">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 d-flex align-items-center">
            <button class="btn btn-primary btn-new" @click="cadCargo">
              Novo Cargo
            </button>
          </div>
        </div>
      </div>

      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Nome
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="cargo in cargos" :key="cargo.id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ cargo.nome }}</h6>
                    </div>
                  </div>
                </td>
                <td class="align-right">
                  <a href="javascript:;" class="text-primary font-weight-bold text-xs" @click="editarCargo(cargo.id)"
                    data-toggle="tooltip" data-original-title="Abrir cargo">Editar</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { buscarCargos } from "@/api/cargo.api";
import BreadcrumbsCargos from "@/components/Breadcrumbs.vue";

export default {
  components: { BreadcrumbsCargos },
  name: "ListaCargosComponent",

  data() {
    return {
      breadcrumbs: [{ label: "Home", to: "/dashboard-admin" }],
      cargos: [],
    };
  },

  mounted() {
    this.carregarCargos();
  },

  methods: {
    async carregarCargos() {
      try {
        this.cargos = await buscarCargos();
      } catch (error) {
        console.error("Erro ao carregar os cargos:", error);
      }
    },

    editarCargo(id) {
      this.$router.push({ path: `/edita-cargo/${id}` });
    },

    cadCargo() {
      this.$router.push("/cad-cargo");
    }
  }
};
</script>

<style scoped>
.input-field,
.search-btn,
.btn-new {
  height: 38px;
  border-radius: 0;
}
</style>
