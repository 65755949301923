import api from "@/api/api";

export async function cadastrarCategoria(nome) {
  const response = await api.post("/categoria/novaCategoria", { nome });
  return response.data;
}

export async function buscarCategorias() {
  const response = await api.get("/categoria/buscaCategorias");
  return response.data.data;
}

export async function buscarCategoriaPorId(id) {
  const response = await api.get(`/categoria/buscaCategoriaPorId/${id}`);
  return response.data.data;
}

export async function atualizarCategoria(id, dados) {
  const response = await api.put(`/categoria/atualizaCategoria/${id}`, dados);
  return response;
}
