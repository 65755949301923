import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import money from "v-money3";
import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { MaskInput } from "vue-3-mask";

const appInstance = createApp(App);
export const baseURL = process.env.VUE_APP_BASE_URL;

appInstance.config.globalProperties.$baseURL = baseURL;

appInstance.component("MaskInput", MaskInput);
appInstance.use(LoadingPlugin);
appInstance.use(Vuetify);
appInstance.use(money);
appInstance.use(Toast);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.mount("#app");
