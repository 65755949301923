<template>
  <BreadcrumbsModalidade :breadcrumbs="breadcrumbs" :router="$router" />

  <div class="py-4 container-fluid">
    <div class="card">
      <div class="container mt-2">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 d-flex align-items-center">
            <button class="btn btn-primary btn-new" @click="cadModalidade">
              Nova Modalidade
            </button>
          </div>
        </div>
      </div>

      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0" v-if="modalidades.length > 0">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Nome
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="modalidade in modalidades" :key="modalidade.id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ modalidade.nome }}</h6>
                    </div>
                  </div>
                </td>
                <td class="align-right">
                  <a href="javascript:;" class="text-primary font-weight-bold text-xs"
                    @click="editarModalidade(modalidade.id)" data-toggle="tooltip"
                    data-original-title="Abrir modalidade">Editar</a>
                </td>
              </tr>
            </tbody>
          </table>

          <p v-if="modalidades.length === 0 && !erro" class="text-center mt-3 text-secondary">
            Nenhuma modalidade encontrada.
          </p>

          <p v-if="erro" class="text-center mt-3 text-danger">
            ⚠️ Ocorreu um erro ao carregar as modalidades. Tente novamente mais tarde.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { buscarModalidades } from "@/api/modalidade.api";
import BreadcrumbsModalidade from "@/components/Breadcrumbs.vue";
import { createToaster } from "@meforma/vue-toaster";

export default {
  components: { BreadcrumbsModalidade },
  name: "ListaModalidadesComponent",

  data() {
    return {
      breadcrumbs: [{ label: "Home", to: "/dashboard-admin" }],
      modalidades: [],
      erro: false,
    };
  },

  mounted() {
    this.carregarModalidades();
  },

  methods: {
    async carregarModalidades() {
      try {
        this.modalidades = await buscarModalidades();
        console.log("--- MODALIDADES ---", this.modalidades);
        this.erro = false;
      } catch (error) {
        console.error("❌ Erro ao buscar modalidades:", error);
        this.erro = true;
        const toaster = createToaster({});
        toaster.error("⚠️ Erro ao carregar modalidades. Tente novamente!");
      }
    },

    editarModalidade(id) {
      this.$router.push({ path: `/edita-modalidade/${id}` });
    },

    cadModalidade() {
      this.$router.push("/cad-modalidade");
    },
  },
};
</script>

<style scoped>
.input-field,
.search-btn,
.btn-new {
  height: 38px;
  border-radius: 0;
}

.select-field {
  width: 40px;
}

.search-input {
  flex-grow: 1;
}
</style>
